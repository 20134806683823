import styles from "./section1.module.css"
import logo from "../../../assets/ImageAssets/marketez.svg"
import { HashLink } from "react-router-hash-link"

const Section1 = () => {


    return (
        <>
            <div className={styles.mainContainer} >
                <div className={styles.flag}>
                    <div className={styles.flagMid}>
                        <div className={styles.flagTop}>
                            EDITION 
                        </div>
                        <div className={styles.flagBottom}>
                            2 0 2 2
                        </div>
                    </div>
                </div>
                <div className={styles.container}>
                    <div className={styles.left}>
                        <div className={styles["left--top"]}>
                            Ez<span>.</span>
                        </div>
                        <div className={styles["left--bottom"]}>
                            <div className={styles["left--bottom-left"]}>
                                MARKET
                            </div>
                            <div className={styles["left--bottom-right"]}>
                                ■ Grow your<br />&nbsp;&nbsp;&nbsp; Business
                            </div>
                        </div>
                    </div>
                    <div className={styles.mid}>
                        <div className={styles.midCircle}>
                            <img src={logo} alt="" />
                        </div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.rightContainer}>
                            <div className={styles.rightTitle}>
                                Affordable Excellence -- that's what we Aim for!
                            </div>
                            <div className={styles.rightPara}>
                                We make offline businesses go online with the help of our innovative team and creatives
                            </div>
                            <div className={styles.rightButton}>
                                <div className={styles.rightButton1}>
                                    <HashLink to="#Contact" smooth className={styles.link}>Contact Now</HashLink>
                                </div>

                                {/* <div className={styles.rightButton2}>READ MORE</div> */}
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )

}

export default Section1