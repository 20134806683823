import styles from "./section4.module.css"
import OptionsCard from "../../../components/Home/OptionsCard"
import { useRef, useState } from "react"
import { useMediaQuery } from 'react-responsive'

import data from "../../../assets/textAsset"

const Section4 = ()=>{

    const cardContainerRef = useRef()
    const [toggle, setToggle] = useState(true)
    const isBigScreen = useMediaQuery({ query: '(min-width: 800px)' })

    const scrollHandler=()=>{
        console.log( cardContainerRef)
        if(isBigScreen){

            if(toggle){
    
                cardContainerRef.current.scrollLeft += window.innerWidth
                // cardContainerRef.current.scrollBottom += window.innerHeight*20
                setToggle(false)
            }
            if(!toggle){
    
                
                cardContainerRef.current.scrollLeft -= window.innerWidth
                // cardContainerRef.current.scrollTop -= window.innerHeight*20
                setToggle(true)
            }
        }
        
        if(!isBigScreen){

            if(toggle){
                
                cardContainerRef.current.scrollTop += window.innerHeight*20
                // cardContainerRef.current.scrollLeft += window.innerWidth
                setToggle(false)
            }
            if(!toggle){
                
                
                cardContainerRef.current.scrollTop -= window.innerHeight*20
                // cardContainerRef.current.scrollLeft -= window.innerWidth
                setToggle(true)
            }
        }
    }   

    return(
        <>
            <div className= {styles.mainContainer} id="Services">
                <div className={styles.container}>
                        <div className={styles.flag}>
                            <div className={styles.flagMid}>
                                <div className={styles.flagTop}>
                                  ■ EDITION 
                                </div>
                                <div className={styles.flagBottom}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;2 0 2 2
                                </div>
                            </div>
                        </div>
                    
                        <div className={styles.title}>
                            <div className={styles.title1}>FIND</div>
                            <div className={styles.title2}>Your Solution</div>
                        </div>
                        <div className={styles.cardsContainer} ref={cardContainerRef}>
                            {data.map((data,index)=><OptionsCard index ={index+1} title={data.title} body={data.body} key={index}/>)}
                            
                            
                        </div>
                        <div className={styles.bottom} onClick={scrollHandler}>
                            MORE SERVICES &nbsp;<span> →</span>
                        </div>
                    
                </div>
            </div>
        </>
    )
}

export default Section4