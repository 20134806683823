import styles from "./section2.module.css"

const Section2 = () => {

    return (
        <>
            <div className={styles.mainContainer}>
                <div className={styles.Container}>
                    <div className={styles.info}>
                        <div className={styles.wrapper}>
                            <div className={styles.nums}>
                                <span>✔</span><div></div>
                            </div>
                            <div className={styles.text}>
                                <div className={styles.textTop}>SATISFIED</div>
                                <div className={styles.textBottom}>CLIENTS</div>
                            </div>
                        </div>

                    </div>
                    <div className={styles.info}>
                        <div className={styles.wrapper}>
                            <div className={styles.nums}>
                                <span>✔</span><div></div>
                            </div>
                            <div className={styles.text}>
                                <div className={styles.textTop}>PROFESSIONAL</div>
                                <div className={styles.textBottom}>TEAM</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.info}>
                        <div className={styles.wrapper}>
                            <div className={styles.nums}>
                                <span>✔</span><div></div>
                            </div>
                            <div className={styles.text}>
                                <div className={styles.textTop}>PREMIUM</div>
                                <div className={styles.textBottom}>SERVICES</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Section2