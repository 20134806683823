import styles from "./Navbar.module.css"
import { HashLink } from "react-router-hash-link"

const Navbar = () => {


    return (
        <>
            <div className={styles.container} id="home">
                <div  className={styles.logo}>
                    Marketez
                </div>
                <div className={styles.menuNav}>

                    <div className={styles.menuItem}><HashLink className={styles.link} smooth to="#home">Home</HashLink></div>
                    <div className={styles.menuItem}><a className={styles.link} href="https://marketez.me/" target="blank">Blogs</a></div>
                    <div className={styles.menuItem}><HashLink className={styles.link} smooth to="#Services">Services</HashLink></div>
                    <div className={styles.menuItem}><HashLink className={styles.link} smooth to="#About">About</HashLink></div>
                    {/* <div className={styles.menuItem}><HashLink className={styles.link} smooth to="#WhyUs">Why Us</HashLink></div> */}
                    <div className={styles.menuItem}><HashLink className={styles.link} smooth to="#Contact">Contact</HashLink></div>
                    

                </div>
                <div className={styles.hamB}>
                    <span className="material-symbols-outlined">
                        menu
                    </span>
                </div>

            </div>
        </>
    )
}

export default Navbar