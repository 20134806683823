// import Home from "./pages/Home/Home";
import "./App.css"
// import Section1 from "./pages/Home/sections/section1";
import Navbar from "./ui/NavBar";
import Footer from "./ui/Footer";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";

import {auth} from "../src/firebase/firebase";
import { useEffect } from "react";
import { signInAnonymously } from "firebase/auth";
function App() {
  var userCred;
  useEffect(()=>{
    signInAnonymously(auth).then((userCredential)=>{userCred = userCredential;
    });
  }, []) 


  return (
    <>
      <Navbar/>
        <Routes>
            <Route path = "/" element={<Home/>} />
            <Route path = "/about" element={<div>About</div>} />
        </Routes>
      <Footer/>
    </>
  );
}

export default App;