
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyCUGuKNbhtFr8KJSe0gxwJ3QM0CGF5o8Zc",
  authDomain: "marketez-295ee.firebaseapp.com",
  projectId: "marketez-295ee",
  storageBucket: "marketez-295ee.appspot.com",
  messagingSenderId: "452795224399",
  appId: "1:452795224399:web:0b1d0b6748fd757aedf53a",
  measurementId: "G-1W1S5ZK6T2"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
export {app}
export {db}
export{auth}