
import styles from "./section3.module.css"
import ring from "../../../assets/ImageAssets/circle web.svg"
import { HashLink } from "react-router-hash-link"


const Section3 = ()=>{


    return (
        <>  
            <div className={styles.mainContainer}>
                <div className={styles.container}>
                    
                    <div className={styles.sideBar1}>■ GROW YOUR BUSINESS</div>
                    <div className={styles.sideBar2}>■ GROW YOUR BUSINESS</div>
                    <div className={styles.midSvg}>
                        <img src={ring} alt=""></img>
                    </div>
                    <div className={styles.midText}>
                        <div className={styles.midTextTop}>
                            ACCEPT
                        </div>
                        <div className={styles.midTextMid}>
                            NEW
                        </div>
                        <div className={styles.midTextBottom}>
                            CHALLENGES
                        </div>
                    </div>
                    <div className={styles.bottomText}>
                        <HashLink to='#Services' className={styles.link}>
                            <span className={styles.bottomText1}>EXPLORE</span>

                        </HashLink>
                        <HashLink to="#About" className={styles.link}>
                            <span className={styles.bottomText2}> ABOUT</span>

                        </HashLink>
                    </div>
                </div>
            </div>

        </>
    )

}

export default Section3