import styles from "./section7.module.css"
import ring from "../../../assets/ImageAssets/circle web.svg"
import {db, auth} from "../../../firebase/firebase";
import {collection , setDoc, doc} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState} from "react";

const Section7 = ()=>{


  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [message, setMessage] = useState('');

    let userObj = auth.currentUser;
    const submitFormHandler = (e)=>{
        e.preventDefault()
        const form = new FormData(e.target)
        console.log(userObj);
        const data = {
            name:form.get('name'),
            email: form.get('email'),
            contact: form.get('contact'),
            message: form.get('message'),
            uid: userObj.uid,
        }
        try{
            setDoc(doc(db,"ContactUs",userObj.uid),data);
            alert('Submitted Successfully')
            setName('')
            setContact('')
            setEmail('')
            setMessage('')
        }catch(e){
            console.log(e);
            alert('There was some error submitting')
        }
    }

    return(
        <>
            <div className= {styles.mainContainer} id='Contact'>
                <div className={styles.container}>
                    <div className={styles.left}>
                        <div className={styles.textContainer}>
                            <div className={styles.textContainerTop}>Market</div>
                            <div className={styles.textContainerMid}>with</div>
                            <div className={styles.textContainerBottom}>EASE</div>
                            <div className={styles.scContainer}>
                                    <img src={ring} alt=""/>
                            </div>

                        </div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.title}>CONTACT US</div>
                        <div className={styles.subTitle}></div>
                        <div className={styles.form}>
                            <form className={styles.mainForm} autoComplete="off" onSubmit={submitFormHandler}>
                                <input required type="text" name="name" placeholder='NAME' value = {name}  onChange={(e) => setName(e.target.value)} ></input>
                                <input required type="email" name="email" placeholder='EMAIL' value = {email} onChange={(e) => setEmail(e.target.value)} ></input>
                                <input required type="tel" name="contact" placeholder='CONTACT US' minLength="10" value = {contact} onChange={(e) => setContact(e.target.value)}></input>
                                <textarea type='message'  maxLength="750" name="message" cols="40" rows="5" placeholder='MESSAGE' value = {message} onChange={(e) => setMessage(e.target.value)}></textarea>
                                <button className={styles.Button}>SEND MESSAGE</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Section7