import styles from "./section5.module.css"
import ring from "../../../assets/ImageAssets/circle web.svg"
import logo from "../../../assets/ImageAssets/marketez.svg"


const Section5 = ()=>{

    return(
        <>
            <div className= {styles.mainContainer} id='About'>
                <div className={styles.svg}>
                    <img src={ring} alt="" ></img>
                </div>
                <div className={styles.container}>
                    <div className={styles.textContainer}>
                        <div className={styles.text}>
                            <div className={styles.title}>About<span> us.</span></div>
                            <div className={styles.para}>
                            We're Marketez - we make offline businesses go online with the help of our innovative team and creatives that make your business stand out and change the way people look at your brand by giving it the Brand Recognition it deserves, engagement, and better ROI.
                            </div>
                            <div className={styles.bulletPoints}>
                                <div className={styles.bullet}><span>■</span> Creative Environment</div>
                                <div className={styles.bullet}><span>■</span> Professionalism</div>
                                <div className={styles.break}></div>
                                <div className={styles.bullet}><span>■</span> Affordable Excellence</div>
                                <div className={styles.bullet}><span>■</span> Reliable Agency </div>
                            </div>
                            {/* <div className={styles.button}>READ MORE</div> */}
                        </div>
                    </div>
                    <div className={styles.svgContainer}>
                        <div className={styles.circle}>
                            <img src={logo} alt=""/>
                        </div>
                        <div className={styles.circle1}></div>
                        <div className={styles.circle2}></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Section5