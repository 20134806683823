const data =[
    {
        title:"Web Development",
        body:"Power your Business with us. Use the right technologies that build a result-oriented website"
    },
    {
        title:"SEO",
        body:"We optimize your campaigns, get you a better Quality Score can get you quality clicks and a better ROI"
    },
    {
        title:"Social Media",
        body:"Get your brand visibility, engagement, and business with our data-backend creative campaign"
    },
    {
        title:"Animation",
        body:"Our team of professional motion graphics designers brings your brand to life and makes the intangible tangible"
    },
    {
        title:"Mobile Application",
        body:"Boost your Business with us. Use the latest technology that builds a result-oriented Application"
    },
    {
        title:"Video Editing",
        body:"Convert your Raw video into professionally edited videos with a team of diligent professionals to offer the best professional Video Editing Services"
    },

    {
        title:"Graphic Design",
        body:"Change the way people look at your brand, with creative concepts and designs made by a team that lives in the detail"
    },
    {
        title:"Web Design",
        body:"Give your business a customer-centric design backed by the science of UX & UI"
    },
    
    
]


export default data