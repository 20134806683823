import styles from "./section6.module.css"
import ring from "../../../assets/ImageAssets/circle web.svg"
import logo from "../../../assets/ImageAssets/logo-04.svg"
// import logo2 from "../../../assets/ImageAssets/marketez.svg"

import { HashLink } from "react-router-hash-link"

const Section6 = ()=>{

    return(
        <>
            <div className= {styles.mainContainer} id="WhyUs">
                <div className={styles.leftText}>
                    <HashLink className={styles.link} to="#Services">EXPLORE</HashLink>
                </div>
                <div className={styles.container}>
                    <div className={styles.svgContainer}>
                        <div className={styles.svg}>
                            <img src={ring} alt="" ></img>
                        </div>
                        <div className={styles.circle}>
                            <img src={logo} alt=""/>
                        </div>
                        
                        <div className={styles.circle2}></div>
                    </div>
                    <div className={styles.textContainer}>
                        <div className={styles.text}>
                            <div className={styles.title}>Why<span> us.</span></div>
                            <div className={styles.para}>
                            We provide marketing and IT solutions to small businesses and startups with premium services at affordable prices with maximum efficiency and help them grow in this digital world in a hassle-free manner so they don't have to worry about anything because it's 'your business but our responsibility
                            </div>
                            
                            {/* <div className={styles.button}>READ MORE</div> */}
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default Section6