import styles from "./Footer.module.css"
import facebook from "../assets/ImageAssets/facebook-svg.svg"
import instagram from "../assets/ImageAssets/instagram-svg.svg"
import linkedin from "../assets/ImageAssets/linkedin-svg.svg"
import twitter from "../assets/ImageAssets/twitter-svg.svg"
import { HashLink } from 'react-router-hash-link';
import {setDoc, doc} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import {db , auth} from "../firebase/firebase";
const Footer = ()=>{

    const [email, setEmail] = useState('')
    let userObj = auth.currentUser;
    const submitFormHandler = (e)=>{
        e.preventDefault()
        const form = new FormData(e.target)
        const data = {
            email:form.get('email'),
            uid: userObj.uid
        }
        try{
            setDoc(doc(db,"Subscribers",email),data);
            alert('Subscribed successfully!')
            setEmail('')
        }catch(e){
            console.log(e);
            alert('There was a problem subscribing')
            
        }
        
    }

    return(
        <>
            <div className = {styles.container}>
                <div className={styles.innerContainer}>
                    <div className={styles.infoContainer}>
                        <div className={styles.info}>
                            {/* <div className={styles.infoTitle}>About</div> */}
                            
                            <HashLink className={styles.link} smooth to="#home">Home</HashLink>
                            <a className={styles.link} href="https://marketez.me/" target="blank">Blogs</a>
                            <HashLink className={styles.link} smooth to="#Services">Services</HashLink>
                            <HashLink className={styles.link} smooth to="#About">About</HashLink>
                            <HashLink className={styles.link} smooth to="#WhyUs">Why Us</HashLink>
                            <HashLink className={styles.link} smooth to="#Contact">Contact</HashLink>
                            
                        </div>
                        <div className={styles.info2}>
                           <div className={styles.info2Title}>Career</div>
                            <div>Send us your resume at</div>
                            <div>info.marketez@gmail.com</div>
                           
                        </div>
                    </div>
                    <div  className={styles.newsLetterContainer}>
                        
                        <div className={styles.nlTitle}>
                            Sign Up to Recieve<br/>Our Newsletter 
                        </div>
                        
                        
                        <form className={styles.mainForm} autoComplete="off" onSubmit={submitFormHandler}>
                                <input required type="email" name="email" placeholder='Enter your Email-id' value={email} onChange={(e) => setEmail(e.target.value)} ></input>
                                <button className={styles.Button}>Subscribe</button>
                        </form>
                            
                        
                        <div className={styles.followUs}>
                            <div className={styles.followUsTitle}>Follow us!</div>
                            <div className={styles.iconsContainer}>
                                <a href="https://www.facebook.com/marketez.in" target="blank"> 
                                    <div><img alt="" src={facebook}/></div>
                                </a>
                                <a href="https://www.instagram.com/marketez_in/" target="blank"> 
                                    <div><img alt="" src={instagram}/></div>
                                </a>
                                <a href="https://www.facebook.com/marketez.in" target="blank"> 
                                    <div><img alt="" src={linkedin}/></div>
                                </a>
                                <a href="https://twitter.com/Marketez_in" target="blank"> 
                                    <div><img alt="" src={twitter}/></div>
                                </a>
                                
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )

}

export default Footer