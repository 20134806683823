import styles from "./OptionsCard.module.css"


const OptionsCard = (props) => {

    return (
        <>
            <div className={styles.outerContainer}>
                <div className={styles.mainContainer}>
                    <div className={styles.flag}>{props.index}</div>
                    <div className={styles.titleContainer}>
                        <div className={styles.title}>
                            {props.title} {/*<span>{props.index}</span>*/}
                        </div>
                        <div className={styles.titleUnderLine}></div>
                    </div>
                    <div className={styles.description}>
                        {props.body}
                    </div>
                    <div className={styles.button}>
                        {/* <div>READ MORE</div> */}
                    </div>
                </div>
            </div>


        </>
    )
}

export default OptionsCard